<template>
  <div class="invoice-page">
    <div class="text-status-right">
      <el-button
        plain
        size="medium"
        @click="applyInvoice"
      >
        申请发票
      </el-button>
    </div>
    <!-- 表格标题 -->
    <div
      class="background-FFFFFF padding-TB-10 border-radius-5 margin-top-10"
    >
      <el-row
        class="font-size-18 color-3F3F3F text-status-center font-weight-500"
      >
        <el-col :span="3">
          <div>发票单号</div>
        </el-col>
        <el-col :span="8">
          <div>发票抬头</div>
        </el-col>
        <el-col :span="4">
          类型
        </el-col>
        <el-col :span="3">
          状态
        </el-col>
        <el-col :span="3">
          金额
        </el-col>
        <el-col :span="3">
          操作
        </el-col>
      </el-row>
    </div>
    <!-- 表格内容 -->
    <div
      class="background-FFFFFF padding-TB-20 border-radius-5 margin-top-20 invoiceList position-relative"
    >
      <Loading :loadingData="{isShow: loading}" />
      <div
        class="hasList"
        v-if="infoData.length > 0"
      >
        <el-row
          v-for="(item, index) in infoData"
          :key="index"
          class="font-size-16 color-3F3F3F text-status-center height-60 line-height-60"
        >
          <el-col :span="3">
            <div>{{ item.FPGL_KPDH || '未知' }}</div>
          </el-col>
          <el-col :span="8">
            <div class="font-weight-600">
              {{ item.FPGL_FPTT || '未知' }}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="color-A9ABA9">
              {{ item.FPGL_PJLX_NAME || item.FPGL_PJLX_CODE || '未知' }}
            </div>
          </el-col>
          <el-col :span="3">
            <div
              class="color-F9514F"
              v-if="item.FPGL_PJZT_CODE=='TD'"
            >
              {{ item.FPGL_PJZT_NAME || item.FPGL_PJZT_CODE || '未知' }}
            </div>
            <div
              style="color: #2FB82D"
              v-if="item.FPGL_PJZT_CODE=='YJ'"
            >
              {{ item.FPGL_PJZT_NAME || item.FPGL_PJZT_CODE || '未知' }}
            </div>
            <div
              style="color: #EB7B1B"
              v-if="item.FPGL_PJZT_CODE=='YK'"
            >
              {{ item.FPGL_PJZT_NAME || item.FPGL_PJZT_CODE || '未知' }}
            </div>
          </el-col>
          <el-col :span="3">
            <div>{{ item.FPGL_FPJE ? '¥' + item.FPGL_FPJE.toFixed(2) : '未知' }}</div>
          </el-col>
          <el-col :span="3">
            <div
              class="cursor-pointer-hover-F9514F"
              @click="goDetails(item)"
            >
              发票详情
            </div>
          </el-col>
        </el-row>
      </div>
      <div
        v-else
        style="text-align: center;padding: 150px 0"
      >
        <img src="../../../../detail/assets/images/alreadyIndent/nullData.png">
        <div class="color-BEBEBE font-size-14">
          暂无数据
        </div>
      </div>
      <div
        class="table-pagination"
        v-if="totalCount > 20"
      >
        <el-pagination
          background
          :page-size="20"
          layout="prev, pager, next, jumper"
          :total="totalCount"
          @current-change="pageHandleChange"
        />
        <i
          class="jeicon jeicon-reduction cursor-pointer-hover-F9514F"
          @click="init"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { POST_INVOICE_INFO } from '@/pages/index/actions/api';
import { isArray } from '@/util/util';

export default {
  data() {
    return {
      infoData: [],
      loading: true,
      totalCount: 0,
      currPage: 1,
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 点击发票申请的按钮
    applyInvoice() {
      this.$router.push({
        path: '/order/invoice/invoiceApply',
      });
    },
    // 查看详情
    goDetails(item) {
      if (!item) {
        return this.$message.error('订单数据无效！');
      }
      this.$router.push({
        path: '/order/invoice/invoiceDetails',
        name: 'invoiceDetailsPage',
        params: {
          item,
        },
      });
    },
    // 点击分页
    pageHandleChange(val) {
      this.currPage = val;
      this.init();
    },
    init() {
      this.$ajax({
        url: POST_INVOICE_INFO,
        data: {
          currPage: this.currPage,
          pageSize: 20,
        },
      }).then((data) => {
        this.loading = false;
        if (!data.data.success) {
          return this.$message({
            type: 'error',
            message: data.data.message,
          });
        }
        this.totalCount = data.data.totalCount;
        this.infoData = isArray(data.data.rows) ? data.data.rows : [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../../style/shop/util';
.invoiceList {
  min-height: 600px;
  .hasList {
    min-height: 300px;
  }
}
</style>

<style lang="less">
.invoice-page {
  .table-pagination {
    margin-top: 15px;
    text-align: right;
    padding-right: 50px;
    position: relative;
    .jeicon {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #F9504F;
    }
  }
}
</style>
